import { Component } from '@angular/core';
// import { SharedService } from './modules/shared/_services/shared.service';
// import { environment } from 'src/environments/environment';

import { GoogleTagManagerService } from './_configs/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class AppComponent {

  title = 'TenderShark';

  latitude: number | undefined;
  longitude: number | undefined;
  errorMessage: string | undefined;

  constructor(
    // private sharedService: SharedService, 
    private gtmService: GoogleTagManagerService) { }

  ngOnInit() {
    this.gtmService.loadGTM();

    // this.injectGtm();
    // for getting current location
    // this.getCurrentLocation();
  }

  // getCurrentLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         this.latitude = position.coords.latitude;
  //         this.longitude = position.coords.longitude;
  //         console.log("lat : ", this.latitude);
  //         console.log("long : ", this.longitude);
  //       },
  //       (error) => {
  //         this.errorMessage = 'Error getting location: ' + error.message;
  //       }
  //     );
  //   } else {
  //     this.errorMessage = 'Geolocation is not supported by this browser.';
  //   }
  // }

  // private injectGtm(): void {
  //   if (!this.sharedService.isBrowser) return;

  //   const gtmId = environment.GTM_ID;
  //   if (!gtmId) return;

  //   this.appendScriptToHead(gtmId);
  //   this.appendNoScriptToBody(gtmId);
  // }

  // private appendScriptToHead(gtmId: string): void {
  //   const script = document.createElement('script');
  //   script.innerHTML = `
  //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //     })(window,document,'script','dataLayer','${gtmId}');
  //   `;
  //   document.head.appendChild(script);
  // }

  // private appendNoScriptToBody(gtmId: string): void {
  //   const noscript = document.createElement('noscript');
  //   noscript.innerHTML = `
  //     <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  //     height="0" width="0" style="display:none;visibility:hidden"></iframe>
  //   `;
  //   document.body.appendChild(noscript);
  // }
}