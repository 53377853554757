export const environment = {
  production: true,
  env: "PROD",
  GTM_ID: "GTM-TXZ9PKGP",
  buildVersion: '2.3.0',
  TENDER: {

    BASE_URL: "https://www.tendershark.com",
    API_BASE_URL: "https://api.tendershark.com/api/",

    IMAGE_BASE_URL: "https://d1tiqtcifmy0l7.cloudfront.net/prod/images/",
    BLOG_BASE_URL: "https://blogs.tendershark.com/",

  },

};
