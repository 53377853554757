import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    public loadGTM(): void {
        if (!isPlatformBrowser(this.platformId)) return;

        const gtmId = environment.GTM_ID;
        if (!gtmId) return;

        this.appendScriptToHead(gtmId);
        this.appendNoScriptToBody(gtmId);
    }

    private appendScriptToHead(gtmId: string): void {
        const script = document.createElement('script');
        script.defer = true;  // Defer script execution for performance
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
        document.head.appendChild(script);
    }

    private appendNoScriptToBody(gtmId: string): void {
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
        document.body.appendChild(noscript);
    }
}