import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './../../modules/auth/_services/auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userDetails = this.authService.getUserDetails() || {};
    const id = userDetails?.id || '';
    const status = userDetails?.status || 'guest';

    let headers = request.headers.set('Access-Control-Allow-Origin', '*');

    if (status === 'loggedin' && id) {
      headers = headers.set('User-ID', id);
    } else if (id) {
      headers = headers.set('Session-ID', id);
    }

    if (isPlatformBrowser(this.platformId)) {
      const pageUrl = typeof window !== 'undefined' && window.location?.href ? window.location.href : '';
      if (pageUrl) {
        headers = headers.set('Page-URL', pageUrl);
      }
    }

    if (request.url?.includes('seo-content') || request.url?.includes('tenderStats') || request.url?.includes('indexing')) {
      request = request.clone({ headers });
    } else if (request.url?.includes('details')) {
      headers = headers.set('Page-URL', request.url);
      request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      catchError((error: any) => this.handleError(error)),
    );
  }

  handleError(error: HttpErrorResponse) {
    // if (error['status'] == 500) {
    // }
    return throwError(error);
  }

}
