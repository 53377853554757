import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }

  login(username: string, userData: string): boolean {
    if (typeof window !== 'undefined' && username) {
      localStorage.setItem('user', JSON.stringify({ username, userData }));
      return true;
    }
    return false;
  }

  logout(): void {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('user');
      window.location.reload();
    }
  }

  isLoggedIn(): boolean {
    return typeof window !== 'undefined' && !!localStorage.getItem('user');
  }

  userId: any;
  sessionId: any;

  getUserDetails(): { id: string, status: string } {
    if (typeof window !== 'undefined') {
      const json = localStorage.getItem('user');
      // Check if user data exists in localStorage and extract userId
      if (json) {
        this.userId = JSON.parse(json).userData?.userId;
        if (this.userId) {
          localStorage.removeItem('sessionId'); // Remove sessionId if userId exists
        }
      }
      // If userId doesn't exist, generate or retrieve sessionId
      if (!this.userId) {
        this.sessionId = localStorage.getItem('sessionId');
        // If no sessionId, generate a new one
        if (!this.sessionId) {
          this.sessionId = this.generateUUID(); // Generate unique sessionId
          localStorage.setItem('sessionId', this.sessionId); // Store sessionId in localStorage
        }
        return { id: this.sessionId, status: 'nonloggedin' };
      }
    }
    return { id: this.userId, status: 'loggedin' };
  }

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) >> 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // userId: any;
  // getUserId(): string {
  //   if (typeof window !== 'undefined') {
  //     const json = localStorage.getItem('user');
  //     if (json) {
  //       this.userId = JSON.parse(json).userData?.userId;
  //       localStorage.removeItem('sessionId');
  //     }
  //     if (!this.userId) {
  //       this.userId = this.generateUUID();
  //       const newUser = {
  //        sessionId: this.userId
  //       };
  //       localStorage.setItem('sessionId', JSON.stringify(newUser)); // Store the generated userId in localStorage
  //     }
  //   }
  //   return this.userId || null; // Return the userId or a fallback
  // }

  // generateUUID(): string {
  //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //     const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) >> 0;
  //     const v = c === 'x' ? r : (r & 0x3) | 0x8;
  //     return v.toString(16);
  //   });
  // }

  getUserData(): any {
    if (typeof window !== 'undefined') {
      const user = localStorage.getItem('user');
      return user ? JSON.parse(user) : null;
    }
    return null;
  }

}